<template>
    <div>
      <div class="container-md mt-5 mt-md-3">
        <div class="row">
          <div class="col-md-5 d-flex align-items-center">
            <div>
              <div class="mb-3 text-center">
                <h2>Inloggen</h2>
                <h6 class="offset-md-1 col-md-10">Welkom bij het klantenportaal van Skipiste Nieuwegein.  Log in om je aan te melden voor een cursus.</h6>
              </div>
              <div
              id='big-message'
              v-if='bigMessage != ""'
              class="offset-md-1 mb-2 animate__animated animate__zoomInUp"
              >{{bigMessage}}</div>
              <form @submit.prevent="submit" class="mb-4">
                  <div class=" d-grid mb-3" id='login-grid'>
                      <div class="mb-3">
                        <label for="identity">E-mailadres</label>
                        <input placeholder='Vul hier jouw e-mailadres in' name='identity' v-model="form.identity" id='identity' type='email' class='form-control'/>
                      </div>
                      <div class="">
                        <label for="password">Wachtwoord</label>
                        <div class="input-group">
                          <input placeholder='Vul hier jouw wachtwoord in' v-model="form.password" id='password' :type="passwordInputType" class='form-control'/>
                          <div class="input-group-append">
                            <span class="input-group-text user-select-none" @mousedown="showPassword = true" @mouseup="showPassword = false" @touchstart="showPassword = true" @touchend="showPassword = false">
                              <font-awesome-icon :icon="showPassword ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mb-3 d-flex justify-content-end">
                        <router-link :to="{name: 'wachtwoord-vergeten'}" class='btn' id='forgot-password'>Wachtwoord vergeten?</router-link>
                    </div>
                  </div>
                  <div class="col-md-12">
                      <p v-if="showError" id="error" class="text-danger p-2 text-center border border-danger rounded">Gebruikersnaam of wachtwoord is incorrect</p>
                  </div>
                  <div class="col-md-12">
                      <button :disabled='isDisabled' class='btn btn-primary w-100 btn-lg mb-2' type="submit">Inloggen <font-awesome-icon v-if="isDisabled" icon="fa-solid fa-spinner" pulse/></button>
                      <router-link class='btn btn-light w-100' :to="{name: 'register'}">Nieuw account aanmaken</router-link>
                        <hr>
                  </div>
                  <div class="col-md-12 text-center mb-5">
                    <p><small>Door jezelf aan te melden, ga je akkoord met de <a target='_blank' href="https://skipistenieuwegein.nl/privacy-policy">privacyvoorwaarden</a> van Skipiste Nieuwegein.</small></p>
                    <p><small>Ben je reeds klant bij onze skischool? Gebruik het e-mailadres waarmee je bij ons bekend bent. Bij twijfel, <a target='_blank' href="tel:+0306884464">bel</a> of <a target='_blank' href="mailto:info@skipistenieuwegein.nl">mail</a> ons even.</small></p>
                  </div>
              </form>
            </div>
          </div>
          <div class="col-md-6 offset-md-1">
            <div
            :style="{ backgroundImage: 'url(' + require('@/assets/site/golden.webp') + ')' }"
            id="login-img"
            class="bg-image-full"
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import 'animate.css'

export default {
  data () {
    return {
      form: {
        Email: '',
        Password: ''
      },
      loading: false,
      showError: false,
      bigMessage: this.$route.params.bigMessage ? this.$route.params.bigMessage : false,
      showPassword: false
    }
  },
  computed: {
    isDisabled: function () {
      return this.loading
    },
    passwordInputType () {
      return this.showPassword ? 'text' : 'password'
    }
  },
  methods: {
    async submit () {
      this.loading = true
      const User = {
        Email: this.form.identity,
        Password: this.form.password
      }
      this.$store.dispatch('auth/login', User).then((res) => {
        if (res.data.isAdmin) {
          this.$router.push({ name: 'Vergaarbak' })
        } else {
          this.$router.push({ name: 'Landing' })
        }
        this.showError = false
        this.loading = false
      }).catch((err) => {
        console.log(err)
        this.showError = true
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
    #big-message{
      background: var(--sp1);
      color: #fff;
      border-radius: 25px;
      width: max-content;
      padding: 6px 12px;
      z-index: 3;
      font-weight: 600;
    }
    #forgot-password{
        font-size: 0.9rem;
        text-align: center;
        padding: 4px 12px;
    }
    #hero h1{
        font-size: 5rem;
        font-weight: 600;
    }
    #login-bar{
        background: #fff;
        border-radius: 25px;
        min-height: 300px;
        z-index: 4;
    }
    #login-grid{
        grid-template-columns: 1fr;
        grid-gap: 12px;
        grid-template-rows: 1fr;
    }
    @media screen and (max-width: 992px){
      #login-grid{
        grid-template-columns: 1fr;
        grid-template-rows: auto;
      }
      #hero h1{
          font-size: 3rem;
          font-weight: 600;
      }
    }
    #login-img{
      height: max(300px, 80vh);
      border-radius: 50px;
      position: relative;
    }
    #login-img span:nth-child(1){
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100px;
      height: 100px;
      background: #fff;
      border-bottom-right-radius: 50px;
    }
    #login-img span:nth-child(2){
      content: '';
      position: absolute;
      left: 0;
      top: 100px;
      width: 50px;
      height: 50px;
      background: transparent;
      border-top-left-radius: 25px;
      box-shadow: 0 -25px 0 0 #fff;
    }
    #login-img span:nth-child(3){
      content: '';
      position: absolute;
      top: 0;
      left: 100px;
      width: 50px;
      height: 50px;
      background: transparent;
      border-top-left-radius: 25px;
      box-shadow: 0 -25px 0 0 #fff;
    }
    @media screen and (max-width: 768px){
      #login-img{
        display: none;
      }
    }

    .input-group-text{
      height: 100%;
      background: var(--background-light);
      border: none;
      border-radius: 0 10px 10px 0;
    }
</style>
